<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="result"
      item-key="name"
      class="elevation-1"
      :search="search"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Search"
          class="mx-4"
        ></v-text-field>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  import axios from "axios";

  export default {
    data () {
      return {
        search: '',
        result: [],
      }
    },
    computed: {
      headers () {
        return [
          {
            text: 'Name',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          {
            text: 'Description',
            value: 'description',
          }
        ]
      },
    },
    mounted() {
        axios({ method: "GET", "url": this.ENDPOINT_GIS_REPOS_API }).then(result => {
            this.result = result.data.repositories;
        }, error => {
            console.error(error);
        });
    }
  }
</script>
