import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';

const getRuntimeConfig = async () => {
  const runtimeConfig = await fetch('/runtimeConfig.json');
  return await runtimeConfig.json()
}

getRuntimeConfig().then(function(json) {
  console.log("Runtime config: " + JSON.stringify(json))
  Vue.config.productionTip = false
  Vue.mixin({
    data() {
      return {
        // Distribute runtime configs into every Vue component
        ENDPOINT_GIS_REPOS_API: json.ENDPOINT_GIS_REPOS_API
      }
    },
  });

  new Vue({
    vuetify,
    render: h => h(App)
  }).$mount('#app')
});
